/* You can add global styles to this file, and also import other style files */
//
//// IONIC STYLES
//
/* Core CSS required for Ionic components to work properly */
@use '@ionic/angular/css/core.css';
/* Basic CSS for apps built with Ionic */
@use '@ionic/angular/css/normalize.css';
@use '@ionic/angular/css/structure.css';
@use '@ionic/angular/css/typography.css';
/* Optional CSS utils that can be commented out */
@use '@ionic/angular/css/padding.css';
@use '@ionic/angular/css/float-elements.css';
@use '@ionic/angular/css/text-alignment.css';
@use '@ionic/angular/css/text-transformation.css';
@use '@ionic/angular/css/flex-utils.css';
@use '@ionic/angular/css/display.css';

@use './theme/partials/variables' as vars;
@use './theme/partials/mixins' as mixins;

@use 'bp-shared-styles/dist/scss/breakpoints/utils' as utils;

@import '@phosphor-icons/web/regular';
@import url('https://fonts.googleapis.com/css2?family=Ubuntu+Sans:ital,wght@0,400..700;1,100..700&display=swap');

@import 'ag-grid-community/styles/ag-grid.css';
/* Quartz Theme Specific CSS */
@import 'ag-grid-community/styles/ag-theme-quartz.css';

// //// CUSTOM - GLOBAL STYLES
//
.ph {
  font-size: inherit;
}

p {
  font-size: var(--font-size-default);
}

.has-w340 {
  --width: 340px;
}

.hide-on-mobile {
  @include mixins.mx-max-width(map-get(vars.$tb-breakpoints, desktop)) {
    display: none;
  }
}

.hide-on-desktop {
  @include mixins.mx-min-width(map-get(vars.$tb-breakpoints, desktop)) {
    display: none;
  }
}

.as-ion-col {
  padding-inline-start: var(--ion-grid-column-padding-xs, var(--ion-grid-column-padding, 5px));
  padding-inline-end: var(--ion-grid-column-padding-xs, var(--ion-grid-column-padding, 5px));
  padding-top: var(--ion-grid-column-padding-xs, var(--ion-grid-column-padding, 5px));
  padding-bottom: var(--ion-grid-column-padding-xs, var(--ion-grid-column-padding, 5px));
  margin: 0px;
  box-sizing: border-box;
  position: relative;
  flex-basis: 0px;
  flex-grow: 1;
  width: 100%;
  max-width: 100%;
  min-height: 1px;

  &[size='3'] {
    flex: 0 0 calc(calc(3 / var(--ion-grid-columns, 12)) * 100%);
    width: calc(calc(3 / var(--ion-grid-columns, 12)) * 100%);
    max-width: calc(calc(3 / var(--ion-grid-columns, 12)) * 100%);
  }

  &[size='4'] {
    flex: 0 0 calc(calc(4 / var(--ion-grid-columns, 12)) * 100%);
    width: calc(calc(4 / var(--ion-grid-columns, 12)) * 100%);
    max-width: calc(calc(4 / var(--ion-grid-columns, 12)) * 100%);
  }

  &[size='6'] {
    flex: 0 0 calc(calc(6 / var(--ion-grid-columns, 12)) * 100%);
    width: calc(calc(6 / var(--ion-grid-columns, 12)) * 100%);
    max-width: calc(calc(6 / var(--ion-grid-columns, 12)) * 100%);
  }

  &[size='8'] {
    flex: 0 0 calc(calc(8 / var(--ion-grid-columns, 12)) * 100%);
    width: calc(calc(8 / var(--ion-grid-columns, 12)) * 100%);
    max-width: calc(calc(8 / var(--ion-grid-columns, 12)) * 100%);
  }

  &[size='12'] {
    flex: 0 0 calc(calc(12 / var(--ion-grid-columns, 12)) * 100%);
    width: calc(calc(12 / var(--ion-grid-columns, 12)) * 100%);
    max-width: calc(calc(12 / var(--ion-grid-columns, 12)) * 100%);
  }
}
