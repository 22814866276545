@use 'sass:math';
@use 'sass:map';

@use '../partials/variables.scss' as vars;

@mixin mx-set-header-main-or-footer($header-class, $main-class, $footer-class) {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;

  @if ($header-class) {
    .#{$header-class} {
      box-sizing: border-box;
      flex: 0 0 auto;
    }

    .#{$main-class} {
      box-sizing: border-box;
      flex: 1 0 auto;
    }

    .#{$footer-class} {
      box-sizing: border-box;
      margin-top: auto;
    }
  }
}

@mixin mx-min-width($breakpoint, $breakpoints: vars.$tb-breakpoints) {
  // If $breakpoint is a key that exists in
  // $breakpoints, get and use the value
  @if map-has-key($breakpoints, $breakpoint) {
    $breakpoint: map-get($breakpoints, $breakpoint);
  }

  @media screen and (min-width: #{$breakpoint}) {
    @content;
  }
}

// Mixin for max-width media queries
@mixin mx-max-width($breakpoint, $breakpoints: vars.$tb-breakpoints) {
  // If $breakpoint is a key that exists in
  // $breakpoints, get and use the value
  @if map-has-key($breakpoints, $breakpoint) {
    $breakpoint: map-get($breakpoints, $breakpoint);
  }

  @media screen and (max-width: #{$breakpoint - 1px}) {
    @content;
  }
}
