/* Ionic Variables and Theming. */
/* This is just a placeholder file For more info, please see: */
/* https://ionicframework.com/docs/theming/basics */

/* To quickly generate your own theme, check out the color generator */
/* https://ionicframework.com/docs/theming/color-generator */

/* TODO: Find all variables that start with the 'var(--ion-color-step-..)' and replace them with the newly added variables. In Ionic v8+, we should remove '--ion-color-step..' */

/* #region COLORS */
:root {
  --ion-color-primary: #4361ee;
  --ion-color-primary-rgb: 67, 97, 238;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #3b55d1;
  --ion-color-primary-tint: #5671f0;

  --ion-color-secondary: #2196f3;
  --ion-color-secondary-rgb: 33, 150, 243;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #1d84d6;
  --ion-color-secondary-tint: #37a1f4;

  --ion-color-tertiary: #805dca;
  --ion-color-tertiary-rgb: 128, 93, 202;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #7152b2;
  --ion-color-tertiary-tint: #8d6dcf;

  --ion-color-success: #00ab55;
  --ion-color-success-rgb: 0, 171, 85;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #00964b;
  --ion-color-success-tint: #1ab366;

  --ion-color-info: #2196f3;
  --ion-color-info-rgb: 33, 150, 243;
  --ion-color-info-contrast: #000000;
  --ion-color-info-contrast-rgb: 0, 0, 0;
  --ion-color-info-shade: #1d84d6;
  --ion-color-info-tint: #37a1f4;

  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  --ion-color-danger: #e92b44;
  --ion-color-danger-rgb: 233, 43, 68;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cd263c;
  --ion-color-danger-tint: #eb4057;

  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  --ion-color-light: #ececec;
  --ion-color-light-rgb: 245, 248, 229;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d8daca;
  --ion-color-light-tint: #f6f9e8;

  --ion-color-white: #ffffff;
  --ion-color-white-rgb: 255, 255, 255;
  --ion-color-white-contrast: #000000;
  --ion-color-white-contrast-rgb: 0, 0, 0;
  --ion-color-white-shade: #e0e0e0;
  --ion-color-white-tint: #ffffff;

  --ion-color-black: #000000;
  --ion-color-black-rgb: 0, 0, 0;
  --ion-color-black-contrast: #ffffff;
  --ion-color-black-contrast-rgb: 255, 255, 255;
  --ion-color-black-shade: #000000;
  --ion-color-black-tint: #1a1a1a;

  --ion-color-brand-primary: #f6ff00;
  --ion-color-brand-primary-rgb: 246, 255, 0;
  --ion-color-brand-primary-contrast: #000000;
  --ion-color-brand-primary-contrast-rgb: 0, 0, 0;
  --ion-color-brand-primary-shade: #d8e000;
  --ion-color-brand-primary-tint: #f7ff1a;

  --ion-color-brand-primary-bg: #1c1c1c;
  --ion-color-brand-primary-bg-rgb: 28, 28, 28;
  --ion-color-brand-primary-bg-contrast: #ffffff;
  --ion-color-brand-primary-bg-contrast-rgb: 255, 255, 255;
  --ion-color-brand-primary-bg-shade: #191919;
  --ion-color-brand-primary-bg-tint: #333333;

  --ion-color-main-header-bg: #1c1c1c;
  --ion-color-main-header-bg-rgb: 28, 28, 28;
  --ion-color-main-header-bg-contrast: #ffffff;
  --ion-color-main-header-bg-contrast-rgb: 255, 255, 255;
  --ion-color-main-header-bg-shade: #191919;
  --ion-color-main-header-bg-tint: #333333;

  --ion-color-surface-background: #fff;
  --ion-color-surface-background-rgb: 255, 255, 255;
  --ion-color-surface-background-contrast: #000000;
  --ion-color-surface-background-contrast-rgb: 0, 0, 0;
  --ion-color-surface-background-shade: #ebebeb;
  --ion-color-surface-background-tint: #ffffff;

  --ion-background-color: #e4e3ef; /* Background color of the entire app */
  --ion-background-color-rgb: 228, 227, 239;

  --ion-text-color: #0b0b0b; /* Text color of the entire app */
  --ion-text-color-rgb: 11, 11, 11;

  --ion-color-step-50: #f3f3f3;
  --ion-color-step-100: #e7e7e7;
  --ion-color-step-150: #dbdbdb;
  --ion-color-step-200: #cfcfcf;
  --ion-color-step-250: #c3c3c3;
  --ion-color-step-300: #b6b6b6;
  --ion-color-step-350: #aaaaaa;
  --ion-color-step-400: #9e9e9e;
  --ion-color-step-450: #929292;
  --ion-color-step-500: #868686;
  --ion-color-step-550: #7a7a7a;
  --ion-color-step-600: #6e6e6e;
  --ion-color-step-650: #626262;
  --ion-color-step-700: #565656;
  --ion-color-step-750: #4a4a4a;
  --ion-color-step-800: #3d3d3d;
  --ion-color-step-850: #313131;
  --ion-color-step-900: #252525;
  --ion-color-step-950: #191919;

  --ion-text-color-step-50: #191919;
  --ion-text-color-step-100: #252525;
  --ion-text-color-step-150: #313131;
  --ion-text-color-step-200: #3d3d3d;
  --ion-text-color-step-250: #4a4a4a;
  --ion-text-color-step-300: #565656;
  --ion-text-color-step-350: #626262;
  --ion-text-color-step-400: #6e6e6e;
  --ion-text-color-step-450: #7a7a7a;
  --ion-text-color-step-500: #868686;
  --ion-text-color-step-550: #929292;
  --ion-text-color-step-600: #9e9e9e;
  --ion-text-color-step-650: #aaaaaa;
  --ion-text-color-step-700: #b6b6b6;
  --ion-text-color-step-750: #c3c3c3;
  --ion-text-color-step-800: #cfcfcf;
  --ion-text-color-step-850: #dbdbdb;
  --ion-text-color-step-900: #e7e7e7;
  --ion-text-color-step-950: #f3f3f3;

  --ion-background-color-step-50: #f3f3f3;
  --ion-background-color-step-100: #e7e7e7;
  --ion-background-color-step-150: #dbdbdb;
  --ion-background-color-step-200: #cfcfcf;
  --ion-background-color-step-250: #c3c3c3;
  --ion-background-color-step-300: #b6b6b6;
  --ion-background-color-step-350: #aaaaaa;
  --ion-background-color-step-400: #9e9e9e;
  --ion-background-color-step-450: #929292;
  --ion-background-color-step-500: #868686;
  --ion-background-color-step-550: #7a7a7a;
  --ion-background-color-step-600: #6e6e6e;
  --ion-background-color-step-650: #626262;
  --ion-background-color-step-700: #565656;
  --ion-background-color-step-750: #4a4a4a;
  --ion-background-color-step-800: #3d3d3d;
  --ion-background-color-step-850: #313131;
  --ion-background-color-step-900: #252525;
  --ion-background-color-step-950: #191919;
}

body.dark,
.md body.dark,
.ios body.dark {
  color-scheme: dark; /* https://www.amitmerchant.com/darkmode-aware-scrollbars-using-css */

  --ion-color-dark: #1c1c1c;
  --ion-color-dark-rgb: 28, 28, 28;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #191919;
  --ion-color-dark-tint: #333333;

  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  --ion-color-light: #ececec;
  --ion-color-light-rgb: 245, 248, 229;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d8daca;
  --ion-color-light-tint: #f6f9e8;

  --ion-color-surface-background: #282828;
  --ion-color-surface-background-rgb: 40, 40, 40;
  --ion-color-surface-background-contrast: #ffffff;
  --ion-color-surface-background-contrast-rgb: 255, 255, 255;
  --ion-color-surface-background-shade: #232323;
  --ion-color-surface-background-tint: #3e3e3e;

  --ion-background-color: #181818; /* Background color of the entire app */
  --ion-background-color-rgb: 24, 24, 24;

  --ion-text-color: #fff; /* Text color of the entire app */
  --ion-text-color-rgb: 255, 255, 255;

  --ion-color-step-50: #191919;
  --ion-color-step-100: #252525;
  --ion-color-step-150: #313131;
  --ion-color-step-200: #3d3d3d;
  --ion-color-step-250: #4a4a4a;
  --ion-color-step-300: #565656;
  --ion-color-step-350: #626262;
  --ion-color-step-400: #6e6e6e;
  --ion-color-step-450: #7a7a7a;
  --ion-color-step-500: #868686;
  --ion-color-step-550: #929292;
  --ion-color-step-600: #9e9e9e;
  --ion-color-step-650: #aaaaaa;
  --ion-color-step-700: #b6b6b6;
  --ion-color-step-750: #c3c3c3;
  --ion-color-step-800: #cfcfcf;
  --ion-color-step-850: #dbdbdb;
  --ion-color-step-900: #e7e7e7;
  --ion-color-step-950: #f3f3f3;

  --ion-text-color-step-50: #f3f3f3;
  --ion-text-color-step-100: #e7e7e7;
  --ion-text-color-step-150: #dbdbdb;
  --ion-text-color-step-200: #cfcfcf;
  --ion-text-color-step-250: #c3c3c3;
  --ion-text-color-step-300: #b6b6b6;
  --ion-text-color-step-350: #aaaaaa;
  --ion-text-color-step-400: #9e9e9e;
  --ion-text-color-step-450: #929292;
  --ion-text-color-step-500: #868686;
  --ion-text-color-step-550: #7a7a7a;
  --ion-text-color-step-600: #6e6e6e;
  --ion-text-color-step-650: #626262;
  --ion-text-color-step-700: #565656;
  --ion-text-color-step-750: #4a4a4a;
  --ion-text-color-step-800: #3d3d3d;
  --ion-text-color-step-850: #313131;
  --ion-text-color-step-900: #252525;
  --ion-text-color-step-950: #191919;

  --ion-background-color-step-50: #191919;
  --ion-background-color-step-100: #252525;
  --ion-background-color-step-150: #313131;
  --ion-background-color-step-200: #3d3d3d;
  --ion-background-color-step-250: #4a4a4a;
  --ion-background-color-step-300: #565656;
  --ion-background-color-step-350: #626262;
  --ion-background-color-step-400: #6e6e6e;
  --ion-background-color-step-450: #7a7a7a;
  --ion-background-color-step-500: #868686;
  --ion-background-color-step-550: #929292;
  --ion-background-color-step-600: #9e9e9e;
  --ion-background-color-step-650: #aaaaaa;
  --ion-background-color-step-700: #b6b6b6;
  --ion-background-color-step-750: #c3c3c3;
  --ion-background-color-step-800: #cfcfcf;
  --ion-background-color-step-850: #dbdbdb;
  --ion-background-color-step-900: #e7e7e7;
  --ion-background-color-step-950: #f3f3f3;
}
/* #endregion COLORS */

/* #region COMPONENTS */
:root {
  --ion-font-family: 'Ubuntu Sans', Arial, 'Helvetica Neue', sans-serif; /* Font family of the app */

  --ion-statusbar-padding: 10px; /* Statusbar padding top of the app */
  --ion-safe-area-top: env(safe-area-inset-top); /* Adjust the safe area inset top of the app */
  --ion-safe-area-bottom: env(safe-area-inset-bottom);
  --ion-safe-area-left: env(safe-area-inset-left);
  --ion-safe-area-right: env(safe-area-inset-right);

  --ion-margin: 10px; /* Adjust the margin of the Margin attributes */
  --ion-padding: 10px; /* Adjust the padding of the Padding attributes */

  --ion-grid-width-xs: 100%;
  --ion-grid-width-sm: 100%;
  --ion-grid-width-md: 100%;
  --ion-grid-width-lg: 1200px;
  --ion-grid-width-xl: 1440px;

  --ion-grid-columns: 12; /* Number of columns in the grid */
  --ion-grid-padding-xs: 0; /* Padding of the grid for xs breakpoints */
  --ion-grid-padding-sm: 0;
  --ion-grid-padding-md: 0;
  --ion-grid-padding-lg: 0;
  --ion-grid-padding-xl: 0;
  --ion-grid-column-padding: var(--size-6px);
  /* --ion-grid-column-padding-xs: 0; /* Padding of the grid columns for xs breakpoints */
  /* --ion-grid-column-padding-sm: 0; */
  /* --ion-grid-column-padding-md: 0; */
  /* --ion-grid-column-padding-lg: 0; */
  /* --ion-grid-column-padding-xl: 0; */

  /* https://ionicframework.com/docs/theming/themes#application-colors */
  --ion-backdrop-color: var(--ion-background-color-step-950); /* Color of the Backdrop component */
  --ion-backdrop-opacity: 0.5; /* Opacity of the Backdrop component */
  --ion-overlay-background-color: var(--ion-background-color-step-50); /* Background color of the overlays */

  --ion-card-background: var(--ion-color-surface-background);
  --ion-border-color: var(--ion-background-color-step-100); /* Border color */
  /* --ion-box-shadow-color: var(--ion-background-color-step-50); /* Box shadow color */

  --ion-tab-bar-background: var(--ion-background-color-step-50); /* Background of the Tab Bar */
  /* --ion-tab-bar-background-focused: #fff; /* Background of the focused Tab Bar */
  /* --ion-tab-bar-border-color: #fff; /* Border color of the Tab Bar */
  /* --ion-tab-bar-color: #fff; /* Color of the Tab Bar */
  /* --ion-tab-bar-color-selected: #fff; /* Color of the selected Tab Button */

  --ion-toolbar-background: var(--ion-color-surface-background); /* Background of the Toolbar */
  /* --ion-toolbar-border-color: #fff; /* Border color of the Toolbar */
  /* --ion-toolbar-color: #fff; /* Color of the components in the Toolbar */

  /* --ion-toolbar-segment-color: #f00; /* Color of the Segment Buttons in the Toolbar */
  /* --ion-toolbar-segment-color-checked: #f00; /* Color of the checked Segment Buttons in the Toolbar */
  /* --ion-toolbar-segment-background: #f00; /* Background of the Segment Buttons in the Toolbar */
  /* --ion-toolbar-segment-background-checked: #f0f; /* Background of the Segment Buttons in the Toolbar */
  /* --ion-toolbar-segment-indicator-color: var(--ion-color-surface-background); /* Color of the Segment Button indicator in the Toolbar */

  --ion-item-background: var(--ion-color-surface-background); /* Background of the Item */
  /* --ion-item-border-color: #fff; /* Border color of the Item */
  /* --ion-item-color: #fff; /* Color of the components in the Item */

  /* --ion-placeholder-color: #fff; /* Color of the placeholder in Inputs */

  ion-split-pane {
    --side-max-width: 300px;
  }

  ion-button {
    text-transform: none;
    --box-shadow: none;
    --border-width: 1px;

    &:not([fill='clear']):not([fill='outline']) {
      --border-width: 1px;
      --border-style: solid;
      --border-color: var(--ion-color-primary-tint);

      &[color='primary'] {
        --border-color: var(--ion-color-primary-tint);
      }

      &[color='secondary'] {
        --border-color: var(--ion-color-secondary-tint);
      }

      &[color='tertiary'] {
        --border-color: var(--ion-color-tertiary-tint);
      }

      &[color='info'] {
        --border-color: var(--ion-color-info-tint);
      }

      &[color='success'] {
        --border-color: var(--ion-color-success-tint);
      }

      &[color='warning'] {
        --border-color: var(--ion-color-warning-tint);
      }

      &[color='danger'] {
        --border-color: var(--ion-color-danger-tint);
      }

      &[color='light'] {
        --border-color: var(--ion-color-light-tint);
      }

      &[color='medium'] {
        --border-color: var(--ion-color-medium-tint);
      }

      &[color='dark'] {
        --border-color: var(--ion-color-dark-tint);
      }

      &[color='white'] {
        --border-color: var(--ion-color-white-tint);
      }

      &[color='black'] {
        --border-color: var(--ion-color-black-tint);
      }

      &[color='brand-primary'] {
        --border-color: var(--ion-color-brand-primary-tint);
      }

      &[color='brand-primary-bg'] {
        --border-color: var(--ion-color-brand-primary-bg-tint);
      }

      &[color='main-header-bg'] {
        --border-color: var(--ion-color-main-header-bg-tint);
      }

      &[color='surface-background'] {
        --border-color: var(--ion-color-surface-background-tint);
      }
    }
  }

  ion-card {
    margin: 0 0 var(--size-10px);
    box-shadow: 0px 1px 10px -3px rgba(0, 0, 0, 0.1);
  }

  ion-segment-button {
    --background-checked: var(--ion-background-color-step-50);
    --indicator-color: var(--ion-color-surface-background);
  }

  ion-item-divider {
    --background: var(--ion-color-surface-background);
  }

  ion-header {
    box-shadow: none;
    border: none !important;
    border-bottom: 1px solid var(--ion-border-color);
  }

  ion-footer {
    box-shadow: none;
    border: none !important;
    border-top: 1px solid var(--ion-border-color);
  }

  ion-content {
    box-shadow: none;

    &::part(scroll) {
      scrollbar-width: thin;
    }
  }

  ion-datetime-button {
    &::part(native) {
      background: var(--ion-color-surface-background-shade);
    }
  }

  ion-datetime {
    --background: var(--ion-color-surface-background);
    --background-rgb: var(--ion-color-surface-background-rgb);
    --title-color: var(--ion-color-surface-background-contrast);

    &::part(month-year-button) {
      background-color: var(--ion-color-surface-background);
      color: var(--ion-color-surface-background-contrast);
    }

    &::part(time-button) {
      background-color: var(--ion-color-surface-background-shade);
      color: var(--ion-color-surface-background-contrast);
    }

    &::part(calendar-day today) {
      font-weight: bold;
      color: var(--ion-color-surface-background-contrast);
      border-color: var(--ion-color-primary-tint);
    }

    &::part(calendar-day active),
    &::part(calendar-day active):focus {
      background-color: var(--ion-color-primary);
      border-color: var(--ion-color-primary-tint);
      color: var(--ion-color-primary-contrast);
    }

    [slot='time-label'] {
      color: var(--ion-color-surface-background-contrast);
    }
  }

  ion-card {
    color: var(--ion-color-surface-background-contrast);

    ion-card-title,
    ion-card-subtitle {
      color: var(--ion-color-surface-background-contrast);
    }

    &[color='primary'] {
      color: var(--ion-color-primary-contrast);
    }

    &[color='secondary'] {
      color: var(--ion-color-secondary-contrast);
    }

    &[color='tertiary'] {
      color: var(--ion-color-tertiary-contrast);
    }

    &[color='info'] {
      color: var(--ion-color-info-contrast);
    }

    &[color='success'] {
      color: var(--ion-color-success-contrast);
    }

    &[color='warning'] {
      color: var(--ion-color-warning-contrast);
    }

    &[color='danger'] {
      color: var(--ion-color-danger-contrast);
    }

    &[color='light'] {
      color: var(--ion-color-light-contrast);
    }

    &[color='medium'] {
      color: var(--ion-color-medium-contrast);
    }

    &[color='dark'] {
      color: var(--ion-color-dark-contrast);
    }

    &[color='white'] {
      color: var(--ion-color-white-contrast);
    }

    &[color='black'] {
      color: var(--ion-color-black-contrast);
    }

    &[color='brand-primary'] {
      color: var(--ion-color-brand-primary-contrast);
    }

    &[color='brand-primary-bg'] {
      color: var(--ion-color-brand-primary-bg-contrast);
    }

    &[color='main-header-bg'] {
      color: var(--ion-color-main-header-bg-contrast);
    }

    &[color='surface-background'] {
      color: var(--ion-color-surface-background-contrast);
    }
  }

  ion-label {
    font-size: var(--size-12px);
  }

  ion-note {
    --color: var(--ion-text-color-step-500);
  }

  ion-item {
    &.custom-item {
      --border-color: var(--ion-border-color);
      --border-width: 1px;
      --border-radius: 6px;
    }
  }

  ion-searchbar {
    padding: 0;
    min-height: auto;
  }

  ion-input,
  ion-textarea,
  ion-select {
    --border-color: var(--ion-border-color);
    --border-width: 1px;
    --border-radius: 6px;
    --padding-start: var(--spacing-small);
    --padding-end: var(--spacing-small);
  }
}

body.dark,
.md body.dark,
.ios body.dark {
  /* https://ionicframework.com/docs/theming/themes#application-colors */
  --ion-backdrop-color: var(--ion-background-color-step-50); /* Color of the Backdrop component */
  --ion-backdrop-opacity: 0.9; /* Opacity of the Backdrop component */
  --ion-overlay-background-color: var(--ion-background-color-step-50); /* Background color of the overlays */

  --ion-card-background: var(--ion-color-surface-background);
  --ion-border-color: var(--ion-background-color-step-200); /* Border color */
  /* --ion-box-shadow-color: var(--ion-background-color-step-50); /* Box shadow color */

  --ion-tab-bar-background: var(--ion-background-color-step-50); /* Background of the Tab Bar */
  /* --ion-tab-bar-background-focused: #fff; /* Background of the focused Tab Bar */
  /* --ion-tab-bar-border-color: #fff; /* Border color of the Tab Bar */
  /* --ion-tab-bar-color: #fff; /* Color of the Tab Bar */
  /* --ion-tab-bar-color-selected: #fff; /* Color of the selected Tab Button */

  --ion-toolbar-background: var(--ion-color-surface-background); /* Background of the Toolbar */
  /* --ion-toolbar-border-color: #fff; /* Border color of the Toolbar */
  /* --ion-toolbar-color: #fff; /* Color of the components in the Toolbar */

  /* --ion-toolbar-segment-color: #f00; /* Color of the Segment Buttons in the Toolbar */
  /* --ion-toolbar-segment-color-checked: #f00; /* Color of the checked Segment Buttons in the Toolbar */
  /* --ion-toolbar-segment-background: #f00; /* Background of the Segment Buttons in the Toolbar */
  /* --ion-toolbar-segment-background-checked: #f0f; /* Background of the Segment Buttons in the Toolbar */
  /* --ion-toolbar-segment-indicator-color: var(--ion-color-surface-background); /* Color of the Segment Button indicator in the Toolbar */

  --ion-item-background: var(--ion-color-surface-background); /* Background of the Item */
  /* --ion-item-border-color: #fff; /* Border color of the Item */
  /* --ion-item-color: #fff; /* Color of the components in the Item */

  /* --ion-placeholder-color: #fff; /* Color of the placeholder in Inputs */

  ion-button {
    &[fill='clear'],
    &[fill='outline'] {
      &:not([color]) {
        --color: var(--ion-color-primary-lighten);
      }
    }
  }

  ion-accordion {
    ion-item[slot='header'] {
      --background: var(--ion-color-primary);
      --color: var(--ion-color-primary-contrast);
    }
  }

  ion-card {
    box-shadow: 0px 1px 10px -3px rgba(0, 0, 0, 0.1);
    border: 1px solid var(--ion-background-color-step-100);
  }

  ion-segment-button {
    --background-checked: var(--ion-background-color-step-50);
    --indicator-color: var(--ion-color-dark);
  }

  ion-input,
  ion-textarea,
  ion-select {
    --background: var(--ion-item-background);
  }
}
/* #endregion COMPONENTS */

/* #region CLASSES */
.ion-color-white {
  --ion-color-base: var(--ion-color-white);
  --ion-color-base-rgb: var(--ion-color-white-rgb);
  --ion-color-contrast: var(--ion-color-white-contrast);
  --ion-color-contrast-rgb: var(--ion-color-white-contrast-rgb);
  --ion-color-shade: var(--ion-color-white-shade);
  --ion-color-tint: var(--ion-color-white-tint);
}

.ion-color-black {
  --ion-color-base: var(--ion-color-black);
  --ion-color-base-rgb: var(--ion-color-black-rgb);
  --ion-color-contrast: var(--ion-color-black-contrast);
  --ion-color-contrast-rgb: var(--ion-color-black-contrast-rgb);
  --ion-color-shade: var(--ion-color-black-shade);
  --ion-color-tint: var(--ion-color-black-tint);
}

.ion-color-info {
  --ion-color-base: var(--ion-color-info);
  --ion-color-base-rgb: var(--ion-color-info-rgb);
  --ion-color-contrast: var(--ion-color-info-contrast);
  --ion-color-contrast-rgb: var(--ion-color-info-contrast-rgb);
  --ion-color-shade: var(--ion-color-info-shade);
  --ion-color-tint: var(--ion-color-info-tint);
}

.ion-color-brand-primary {
  --ion-color-base: var(--ion-color-brand-primary);
  --ion-color-base-rgb: var(--ion-color-brand-primary-rgb);
  --ion-color-contrast: var(--ion-color-brand-primary-contrast);
  --ion-color-contrast-rgb: var(--ion-color-brand-primary-contrast-rgb);
  --ion-color-shade: var(--ion-color-brand-primary-shade);
  --ion-color-tint: var(--ion-color-brand-primary-tint);
}

.ion-color-brand-primary-bg {
  --ion-color-base: var(--ion-color-brand-primary-bg);
  --ion-color-base-rgb: var(--ion-color-brand-primary-bg-rgb);
  --ion-color-contrast: var(--ion-color-brand-primary-bg-contrast);
  --ion-color-contrast-rgb: var(--ion-color-brand-primary-bg-contrast-rgb);
  --ion-color-shade: var(--ion-color-brand-primary-bg-shade);
  --ion-color-tint: var(--ion-color-brand-primary-bg-tint);
}

.ion-color-main-header-bg {
  --ion-color-base: var(--ion-color-main-header-bg);
  --ion-color-base-rgb: var(--ion-color-main-header-bg-rgb);
  --ion-color-contrast: var(--ion-color-main-header-bg-contrast);
  --ion-color-contrast-rgb: var(--ion-color-main-header-bg-contrast-rgb);
  --ion-color-shade: var(--ion-color-main-header-bg-shade);
  --ion-color-tint: var(--ion-color-main-header-bg-tint);
}

.ion-color-surface-background {
  --ion-color-base: var(--ion-color-surface-background);
  --ion-color-base-rgb: var(--ion-color-surface-background-rgb);
  --ion-color-contrast: var(--ion-color-surface-background-contrast);
  --ion-color-contrast-rgb: var(--ion-color-surface-background-contrast-rgb);
  --ion-color-shade: var(--ion-color-surface-background-shade);
  --ion-color-tint: var(--ion-color-surface-background-tint);
}
/* #endregion CLASSES */

body.dark,
.md body.dark,
.ios body.dark {
  /* #region AG GRID */
  .ag-theme-quartz {
    --ag-background-color: var(--ion-color-surface-background); /* Dark background */
    --ag-foreground-color: var(--ion-text-color); /* Light text */
    --ag-header-background-color: var(--ion-color-surface-background-shade); /* Dark header */
    --ag-header-foreground-color: var(--ion-text-color); /* Light header text */
    --ag-secondary-foreground-color: var(--ion-text-color-shade); /* Light grey for secondary text */
    --ag-data-color: var(--ion-text-color); /* Light text in grid cells */
    --ag-tooltip-background-color: #333333; /* Dark tooltip background */
    --ag-selected-row-background-color: #444444; /* Dark background for selected row */
    --ag-row-hover-color: #555555; /* Darker background on row hover */
    --ag-column-hover-color: #555555; /* Darker background on column hover */
    --ag-border-color: var(--ion-border-color); /* Grey border */
    --ag-row-border-color: #666666; /* Grey row border */
  }
  /* #endregion AG GRID */
}
